.block {
  & :global(.slide) {
    @apply flex flex-col md:flex-row md:space-x-8 justify-center;

    & :global(.text) {
      @apply w-full md:w-1/2 px-12 flex justify-center items-center;

      & :global(p) {
        @apply text-xl md:text-2.5xl font-bold font-libre pb-4 text-center md:text-left;
      }

      & :global(span) {
        @apply w-full inline-block text-base text-center md:text-left lg:text-lg;
      }
    }

    & :global(.image) {
      @apply w-full md:w-1/2 pb-8 md:pb-0;
    }
  }

  & :global(.navigation) {
    @apply flex justify-center items-center mt-8;

    & :global(.count) {
      @apply px-4;
    }
  }
}

.arrow {
  @apply text-center text-base flex items-center min-w-22 md:min-w-14;

  & :global(span) {
    @apply px-2 md:hidden;
  }

  & :global(.circle) {
    @apply flex items-center justify-center w-10 h-10 border;
    @apply rounded-full cursor-pointer hover:bg-good-green-10;
  }

  &:disabled {
    @apply opacity-50 cursor-default;
  }

  &:global(.prev) {
    & :global(.circle) {
      @apply flip-horizontal;
    }
  }

  &:global(.next) {
    @apply flex-row-reverse;
  }
}
